import React from "react"
import SEO from "../components/utils/SEO"
import Layout from "../components/layout/Layout"
import LayoutWithImage from "../components/layout/LayoutWithImage"

const IndexPage = () => (
  <Layout>
    <SEO title="Berufseinstieg" />

    <div className="pt-5 header">
      <h1>Berufseinstieg</h1>
      <h2>Überblick über die Angebote der Amtsstellen und der AHV-IV-FAK-Anstalten im Bereich Bildung und Arbeit.</h2>
    </div>

    <LayoutWithImage>
      <p>&laquo;sichtwechsel&raquo; hat einen Überblick zum Thema Berufseinstieg zusammengestellt.</p>
      <p>Die Angebote für Menschen mit einer Behinderung und Unterstützungsbedarf sind hier auf einen Blick zu sehen.</p>
      <p>Betroffene und Beratende haben so einen klaren Überblick über die Angebote.</p>
      <p>Der Überblick soll zur besseren Integration von Menschen mit Behinderung beitragen.</p>
      <p><a href="http://www.llv.li/files/scg/uberblick-uber-die-angebote-der-amtsstellen-und-der-ahv-mai-2016.pdf" title="Angebotsübersicht" rel="external" target="_blank">Hier finden Sie den Angebotsüberblick</a> zum Herunterladen.</p>
    </LayoutWithImage>

  </Layout>
)

export default IndexPage
